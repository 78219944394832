<!--Footer Starts-->
<footer class="footer footer-static footer-light navbar-border" id="stackFooter">
    <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2">
        <span class="float-md-left d-block d-md-inline-block">Copyright &copy; {{currentDate | date: 'yyyy'}}
            <a href="https://themeforest.net/user/pixinvent/portfolio?ref=pixinvent" target="_blank" class="text-bold-800 grey darken-2">PIXINVENT </a>, All rights reserved. </span>
        <span class="float-md-right d-block d-md-inline-blockd-none d-lg-block">Hand-crafted & Made with
            <i class="ft-heart pink"></i>
        </span>
    </p>
</footer>
<!--Footer Ends-->