
<!-- <div class="app-body" >
  <div class="header" >
    <app-navbar></app-navbar>
  </div>
  <div class="main">
    <div class="grid">
      <div class="col-2">
        <app-sidebar class="left-sidebar"></app-sidebar>
      </div>
      <div class="col-10">
          <router-outlet></router-outlet>

      </div>
  </div>
  </div>

</div> -->


<div class="app-body" >
  <div class="header" >
    <app-navbar></app-navbar>
  </div>
  <div class="main ">
    <app-sidebar></app-sidebar>
    <router-outlet></router-outlet>
  </div>

</div>


