import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { forkJoin, from, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as numeral from 'numeral';
import { AuthService } from './auth.service';
const apiUmee = environment.apiInvestBase;
const apiCore = environment.apiCoreBase;
const queryString = require('query-string');
@Injectable()
export class ApiServiceV2Service {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService) { }

  options = {
    headers: new HttpHeaders({
      Authorization: this.authService.getAuthorizationHeaderValue(),
      'Content-Type': 'application/json',
    })
  };

  getBankListV2(field_name): Observable<any> {
    return this.httpClient.get(`${apiUmee}/api/v2/account/GetBankList`, this.options).pipe(
      map((repon: any) => {
        if (repon.status === 'success' && repon.data && repon.data.length > 0) {
          return {
            key: field_name, result: repon.data.map(item => {
              return {
                label: item.bank_name + '-' + item.bank_code,
                value: item.bank_code,
                ...item
              }
            })
          };
        } else {
          return { key: field_name, result: [] }
        }

      }), catchError(error => {
        return throwError('Capital not found!');
      })
    )
  }
  
  getAgencyPolicyListV2(field_name): Observable<any> {
    return this.httpClient.get(`${apiUmee}/api/v2/agency/GetAgencyPolicyList`, this.options).pipe(
      map((repon: any) => {
        if (repon.status === 'success' && repon.data && repon.data.length > 0) {
          return {
            key: field_name, result: repon.data.map(item => {
              return {
                label: item.iap_name + '-' + item.iap_cd,
                value: item.iap_id,
                ...item
              }
            })
          };
        } else {
          return { key: field_name, result: [] }
        }

      }), catchError(error => {
        return throwError('Capital not found!');
      })
    )
  }

  getConvertListV2(field_name): Observable<any> {
    return this.httpClient.get(`${apiUmee}/api/v2/schemeconvert/GetConvertList?channel=UMEE`, this.options).pipe(
      map((repon: any) => {
        if (repon.status === 'success' && repon.data && repon.data.length > 0) {
          return {
            key: field_name, result: repon.data.map(item => {
              return {
                label: item.convert_name + '-' + item.convert_cd,
                value: item.convertId,
                ...item
              }
            })
          };
        } else {
          return { key: field_name, result: [] }
        }

      }), catchError(error => {
        return throwError('Capital not found!');
      })
    )
  }
 
  getOpenSchemesV2(queryParams, field_name): Observable<any> {
    return this.httpClient.get(`${apiUmee}/api/v2/open/GetOpenSchemes?` + queryParams, this.options).pipe(
      map((repon: any) => {
        if (repon.status === 'success' && repon.data && repon.data.length > 0) {
          return {
            key: field_name, result: repon.data.map(item => {
              return {
                label: item.scheme_desc + '-' + item.scheme_cd,
                value: item.schemeId,
                ...item
              }
            })
          };
        } else {
          return { key: field_name, result: [] }
        }

      }), catchError(error => {
        return throwError('Capital not found!');
      })
    )
  }

  getOpenTenorListV2(queryParams, field_name): Observable<any> {
    return this.httpClient.get(`${apiUmee}/api/v2/openscheme/GetOpenTenorList?` + queryParams, this.options).pipe(
      map((repon: any) => {
        if (repon.status === 'success' && repon.data && repon.data.length > 0) {
          return {
            key: field_name, result: repon.data.map(item => {
              return {
                label: item.tenor_name,
                value: item.tenorId,
                name: item.tenor_name,
                code: item.tenorId,
                ...item
              }
            })
          };
        } else {
          return { key: field_name, result: [] }
        }

      }), catchError(error => {
        return throwError('Capital not found!');
      })
    )
  }
 
  getCustObjectListV2(url, field_name): Observable<any> {
    return this.httpClient.get(`${apiUmee}` + url, this.options).pipe(
      map((repon: any) => {
        if (repon.status === 'success' && repon.data && repon.data.length > 0) {
          return {
            key: field_name, result: repon.data.map(item => {
              return {
                label: item.name,
                name: item.name,
                code: item.value,
                ...item
              }
            })
          };
        } else {
          return { key: field_name, result: [] }
        }

      }), catchError(error => {
        return throwError('Capital not found!');
      })
    )
  }

  getAccountPageV2(queryParams, field_name): Observable<any> {
    return this.httpClient.get(`${apiUmee}/api/v2/account/GetAccountPage?` + queryParams, this.options).pipe(
      map((repon: any) => {
        if (repon.status === 'success' && repon.data && repon.data.dataList && repon.data.dataList.data.length > 0) {
          return {
            key: field_name, result: repon.data.dataList.data.map(item => {
              return {
                label: item.acc_no + '-' + item.bank_name,
                value: item.base_no,
                ...item
              }
            })
          };
        }else {
          return { key: field_name, result: [] }
        }

      }), catchError(error => {
        return throwError('Capital not found!');
      })
    )
  }

  getProjectListV2(queryParams, field_name): Observable<any> {
    return this.httpClient.get(`${apiUmee}/api/v2/product/GetProjectList?` + queryParams, this.options).pipe(
      map((repon: any) => {
        if (repon.status === 'success' && repon.data) {
          return {
            key: field_name, result: repon.data.map(item => {
              return {
                label: item.project_name ,
                value: item.project_cd,
                ...item
              }
            })
          };
        }else {
          return { key: field_name, result: [] }
        }

      }), catchError(error => {
        return throwError('Capital not found!');
      })
    )
  }
  

  getProductListV2(field_name): Observable<any> {
    return this.httpClient.get(`${apiUmee}/api/v2/product/GetProductList`, this.options).pipe(
      map((repon: any) => {
        if (repon.status === 'success' && repon.data) {
          return {
            key: field_name, result: repon.data.map(item => {
              return {
                label: item.prod_desc ,
                value: item.prod_cd,
                ...item
              }
            })
          };
        }else {
          return { key: field_name, result: [] }
        }

      }), catchError(error => {
        return throwError('Capital not found!');
      })
    )
  }
  
  getAccountListV2(queryParams, field_name): Observable<any> {
    return this.httpClient.get(`${apiUmee}/api/v2/account/getAccountList?` + queryParams, this.options).pipe(
      map((repon: any) => {
        if (repon.status === 'success' && repon.data) {
          return {
            key: field_name, result: repon.data.map(item => {
              return {
                label: item.name,
                value: item.value,
                ...item
              }
            })
          };
        }else {
          return { key: field_name, result: [] }
        }

      }), catchError(error => {
        return throwError('Capital not found!');
      })
    )
  }

  getAccountListCoreV2(queryParams, field_name): Observable<any> {
    return this.httpClient.get(`${apiCore}/api/v1/coreaccount/getAccountList?` + queryParams, this.options).pipe(
      map((repon: any) => {
        if (repon.status === 'success' && repon.data) {
          return {
            key: field_name, result: repon.data.map(item => {
              return {
                label: item.acc_no + '-' + item.link_acc_bank,
                value: item.acc_no,
                ...item
              }
            })
          };
        }else {
          return { key: field_name, result: [] }
        }

      }), catchError(error => {
        return throwError('Capital not found!');
      })
    )
  }



  getAllOpenData(url, field_name, columnValue, custId): Observable<any> {
      if(columnValue) {
        const params = queryString.stringify({ open_id: columnValue, custId: custId});
        const A = this.GetOpenListV2(url, field_name);
        const B = this.getOpenDetailV2(params);
       return forkJoin([A, B]).pipe(
          map((results: any) => {
            return {
            key: field_name, result: results[0].result, open_detail: results[1].result
          };
        }))

      }else {
        this.GetOpenListV2(url, field_name)
      }
  }

  GetOpenListV2(url, field_name): Observable<any> {
    return this.httpClient.get(`${apiUmee}` + url, this.options).pipe(
      map((repon: any) => {
        if (repon.status === 'success' && repon.data) {
          return {
            key: field_name, result: repon.data.map(item => {
              return {
                label: item.name,
                ...item
              }
            })
          };
        }else {
          return { key: field_name, result: [] }
        }

      }), catchError(error => {
        return throwError('Capital not found!');
      })
    )
  }

  getOpenDetailV2(queryParams): Observable<any> {
    return this.httpClient.get(`${apiUmee}/api/v2/open/GetOpenDetail?` + queryParams, this.options).pipe(
      map((repon: any) => {
        if (repon.status === 'success' && repon.data) {
          return {
            key: null, result: repon.data
          };
        }else {
          return { key: null, result: [] }
        }

      }), catchError(error => {
        return throwError('Capital not found!');
      })
    )
  }

}

